import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import server from "../../api/Server";

const TimeSeriesGraph = () => {
    const [loading, setLoading] = useState(false);
    const [graphData, setGraphData] = useState(null);

    const getGraphData = async () => {
        try {
            setLoading(true);
            let res = await server.getGraphData();
            if (res.data.code !== 200) {
                setLoading(false);
                return;
            }
            const timeSeriesData = res.data.data['Time Series (Daily)'];
            const formattedData = Object.keys(timeSeriesData).map(date => ({
                date,
                open: parseFloat(timeSeriesData[date]["1. open"]),
                high: parseFloat(timeSeriesData[date]["2. high"]),
                low: parseFloat(timeSeriesData[date]["3. low"]),
                close: parseFloat(timeSeriesData[date]["4. close"]),
                volume: parseInt(timeSeriesData[date]["5. volume"], 10)
            }));
            const latest20DataPoints = formattedData.slice(0, 10).reverse();
            setGraphData(latest20DataPoints);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getGraphData();
    }, [])

    return (
        <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Stock Price Time Series</h2>
            {loading ? (
                <div className="flex items-center justify-center h-96">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
                </div>
            ) : graphData ? (
                <div className="h-96">
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            data={graphData}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" className="opacity-30" />
                            <XAxis 
                                dataKey="date" 
                                tick={{ fill: '#4B5563' }} 
                                tickFormatter={(value) => new Date(value).toLocaleDateString()}
                            />
                            <YAxis tick={{ fill: '#4B5563' }} />
                            <Tooltip 
                                contentStyle={{ backgroundColor: '#F3F4F6', border: 'none', borderRadius: '0.375rem' }}
                                labelStyle={{ color: '#1F2937', fontWeight: 'bold' }}
                            />
                            <Legend wrapperStyle={{ paddingTop: '20px' }} />
                            <Line type="monotone" dataKey="open" stroke="#3B82F6" strokeWidth={2} dot={false} />
                            <Line type="monotone" dataKey="high" stroke="#10B981" strokeWidth={2} dot={false} />
                            <Line type="monotone" dataKey="low" stroke="#EF4444" strokeWidth={2} dot={false} />
                            <Line type="monotone" dataKey="close" stroke="#F59E0B" strokeWidth={2} dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <div className="flex items-center justify-center h-96 text-gray-500">
                    No data available
                </div>
            )}
        </div>
    )
}

export default TimeSeriesGraph;