"use client";

import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { ImLinkedin, ImWhatsapp } from "react-icons/im";
import { FaInstagram } from "react-icons/fa6";

const Footer = () => {
	const currentYear = new Date().getFullYear();
	const gotoLinks = (val) => {
		window.open(val, "_blank");
	};

	return (
		<footer className="bg-gradient-to-b from-[#010313] to-[#330023] text-white py-12 transition-all duration-200 font-sans">
			<div className="container mx-auto px-4">
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
					<div className="flex flex-col items-center md:items-start">
						<img
							src="/icons/AIFT2.png"
							alt="Company Logo"
							className="w-24 h-24 rounded-full mb-4"
						/>
						<h3 className="text-4xl font-bold mb-4">AIFT</h3>
					</div>
					<div>
						<h3 className="text-xl font-semibold mb-4">Product</h3>
						<ul className="space-y-2 md:space-y-3">
							<li className="text-lg">Features</li>
							<li className="text-lg">Pricing</li>
							<li className="text-lg">Customer Stories</li>
							<li className="text-lg">Demo</li>
						</ul>
					</div>
					<div>
						<h3 className="text-xl font-semibold mb-4">Company</h3>
						<ul className="space-y-2 md:space-y-3">
							<li className="text-lg">About us</li>
							<li className="text-lg">News</li>
							<li className="text-lg">Career</li>
							<li className="text-lg">Insight</li>
						</ul>
					</div>
					<div>
						<h3 className="text-xl font-semibold mb-4">Legal</h3>
						<ul className="space-y-2 md:space-y-3">
							<li className="text-lg">Privacy Policy</li>
							<li className="text-lg">Terms & Service</li>
							<li className="text-lg">Imprint</li>
						</ul>
					</div>
					<div>
						<h3 className="text-xl font-semibold mb-4">Contact Us</h3>
						<p className="flex items-center text-lg mb-2">
							<FaMapMarkerAlt className="mr-2" />
							HSR Layout, Bangalore
						</p>
						<p className="flex items-center text-lg mb-2">
							<FaPhoneAlt className="mr-2" />
							+91-7510005269
						</p>
						<p className="flex items-center text-lg mb-4">
							<FaEnvelope className="mr-2" />
							tanvit@ai-ft.in
						</p>
						<div className="flex space-x-4 md:space-x-6">
							<FaInstagram
								className="text-2xl cursor-pointer hover:text-gray-300 transition-colors"
								onClick={() =>
									gotoLinks("https://www.instagram.com/aift_main/")
								}
							/>
							<ImLinkedin
								className="text-2xl cursor-pointer hover:text-gray-300 transition-colors"
								onClick={() =>
									gotoLinks("https://www.linkedin.com/company/aift-ai")
								}
							/>
							<ImWhatsapp
								className="text-2xl cursor-pointer hover:text-gray-300 transition-colors"
								onClick={() => gotoLinks("https://wa.me/7510005269")}
							/>
						</div>
					</div>
				</div>
				<hr className="border-t border-white w-1/2 mx-auto my-8" />
				<div className="text-center">
					<p>
						&copy; {currentYear} AIFT. All rights reserved || Developed by{" "}
						<span className="text-red-500 animate-pulse inline-block">❤</span>{" "}
						AIFT members
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
