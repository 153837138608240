import { ChevronDownIcon } from "lucide-react";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CalculatorPage = () => {
	const [selectedCalculator, setSelectedCalculator] =
		useState("EMI Calculator");
	const [inputs, setInputs] = useState({
		input1: "",
		input2: "",
		input3: "",
	});
	const [calculated, setCalculated] = useState(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility

	const calculatorConfig = {
		"EMI Calculator": {
			title: "EMI Calculator",
			input1: {
				label: "Principal loan amount",
				placeholder: "Enter principal loan amount",
			},
			input2: {
				label: "Monthly Interest Rate",
				placeholder: "Enter monthly interest rate",
			},
			input3: {
				label: "Number of months",
				placeholder: "Enter number of monthly installments",
			},
			resultLabel: "Monthly repayment value will be",
		},
		"Mutual Funds Calculator": {
			title: "Mutual Funds Calculator",
			input1: {
				label: "Amount Invested",
				placeholder: "Enter initial investment amount",
			},
			input2: {
				label: "Interest Rate",
				placeholder: "Enter annual rate of return (%)",
			},
			input3: {
				label: "Investment Period",
				placeholder: "Enter number of years",
			},
			resultLabel: "Future Value will be",
		},
		"SIP Calculator": {
			title: "SIP Calculator",
			input1: {
				label: "Amount Invested",
				placeholder: "Enter monthly investment amount",
			},
			input2: {
				label: "Interest Rate",
				placeholder: "Enter annual rate of return (%)",
			},
			input3: {
				label: "Investment Period",
				placeholder: "Enter number of months",
			},
			resultLabel: "Future Value will be",
		},
		"Income Tax Calculator": {
			title: "Income Tax Calculator",
			input1: { label: "CTC", placeholder: "Enter your CTC" },
			input2: {
				label: "PF Contribution by Employer",
				placeholder: "Enter the PF Contribution",
			},
			input3: { label: "", placeholder: "" },
			resultLabel: "Total Tax to be paid is",
		},
		"Retirement Planning Calculator": {
			title: "Retirement Planning Calculator",
			input1: { label: "Annual Savings", placeholder: "Enter annual savings" },
			input2: {
				label: "Annual Interest Rate",
				placeholder: "Enter annual interest rate",
			},
			input3: {
				label: "Years Until Retirement",
				placeholder: "Enter years until retirement",
			},
			resultLabel: "Total amount saved by the time of retirement will be",
		},
	};

	const handleInputChange = (e, inputKey) => {
		setInputs((prev) => ({ ...prev, [inputKey]: e.target.value }));
	};

	const handleCalculate = () => {
		if (
			!inputs.input1 ||
			!inputs.input2 ||
			(selectedCalculator !== "Income Tax Calculator" && !inputs.input3)
		) {
			toast.error("Please fill all required fields!");
			return;
		}

		let result;
		switch (selectedCalculator) {
			case "EMI Calculator":
				const P = parseFloat(inputs.input1);
				const r = parseFloat(inputs.input2) / 100 / 12;
				const n = parseInt(inputs.input3);
				result = (P * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
				break;
			case "Mutual Funds Calculator":
				const principal = parseFloat(inputs.input1);
				const rate = parseFloat(inputs.input2) / 100;
				const time = parseInt(inputs.input3);
				result = principal * Math.pow(1 + rate, time);
				break;
			case "SIP Calculator":
				const monthlyInvestment = parseFloat(inputs.input1);
				const monthlyRate = parseFloat(inputs.input2) / 100 / 12;
				const numMonths = parseInt(inputs.input3);
				result =
					monthlyInvestment *
					((Math.pow(1 + monthlyRate, numMonths) - 1) / monthlyRate) *
					(1 + monthlyRate);
				break;
			case "Income Tax Calculator":
				const ctc = parseFloat(inputs.input1);
				const pfContribution = parseFloat(inputs.input2);
				let taxableIncome = ctc - 50000 - pfContribution;
				if (taxableIncome <= 250000) {
					result = 0;
				} else if (taxableIncome <= 500000) {
					result = 0.05 * (taxableIncome - 250000);
				} else if (taxableIncome <= 1000000) {
					result = 0.05 * 250000 + 0.2 * (taxableIncome - 500000);
				} else {
					result =
						0.05 * 250000 + 0.2 * 500000 + 0.3 * (taxableIncome - 1000000);
				}
				result *= 1.04; // 4% cess
				break;
			case "Retirement Planning Calculator":
				const annualSavings = parseFloat(inputs.input1);
				const interestRate = parseFloat(inputs.input2) / 100;
				const years = parseInt(inputs.input3);
				const futureValueFactor =
					(Math.pow(1 + interestRate, years) - 1) / interestRate;
				result = futureValueFactor * annualSavings * (1 + interestRate);
				break;
			default:
				result = 0;
		}

		setCalculated(result.toFixed(2));
	};

	const handleGetAIInsights = () => {
		if (calculated === null) {
			toast.error("Please calculate the result first!");
			return;
		}

		let insights = "";
		switch (selectedCalculator) {
			case "EMI Calculator":
				insights = `Your monthly EMI is approximately ${calculated}. Consider reviewing your loan terms to see if you can secure a better interest rate.`;
				break;
			case "Mutual Funds Calculator":
			case "SIP Calculator":
				insights = `Based on your investment, you can expect to have ${calculated} in the future. It's advisable to review your investment strategy annually.`;
				break;
			case "Income Tax Calculator":
				insights = `Your total tax liability of ${calculated} suggests that you might want to explore tax-saving investment options for better financial health.`;
				break;
			case "Retirement Planning Calculator":
				insights = `By saving ${inputs.input1} annually, your retirement corpus will be ${calculated}. Consider increasing your savings rate to ensure a comfortable retirement.`;
				break;
			default:
				insights = "No insights available for this calculator.";
		}

		toast.success(insights);
	};

	return (
		<div className="container mx-auto p-4">
			<h1 className="text-3xl font-bold text-center mb-8 text-gray-800">
				Financial Calculators
			</h1>
			<div className="flex flex-col md:flex-row gap-8">
				{/* Left container: Calculator */}
				<div className="w-full md:w-1/3 bg-white rounded-lg shadow-md p-6">
					<h2 className="text-xl font-bold mb-4 text-gray-800">
						Choose Calculator
					</h2>
					{/* Dropdown for small screens */}
					<div className="md:hidden relative">
						<button
							onClick={() => setIsDropdownOpen(!isDropdownOpen)}
							className="w-full text-left px-4 py-2 rounded bg-gray-100 text-gray-800 hover:bg-gray-200 flex items-center justify-between"
						>
							{selectedCalculator}
							<ChevronDownIcon className="h-5 w-5 text-gray-600" />{" "}
						</button>

						{/* Chevron down icon */}
						{isDropdownOpen && (
							<div className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg shadow-lg">
								{Object.keys(calculatorConfig).map((calcType) => (
									<button
										key={calcType}
										onClick={() => {
											setSelectedCalculator(calcType);
											setInputs({ input1: "", input2: "", input3: "" });
											setCalculated(null);
											setIsDropdownOpen(false); // Close dropdown on selection
										}}
										className={`w-full text-left px-4 py-2 rounded transition duration-300 ${
											selectedCalculator === calcType
												? "bg-blue-600 text-white"
												: "bg-gray-100 text-gray-800 hover:bg-gray-200"
										}`}
									>
										{calcType}
									</button>
								))}
							</div>
						)}
					</div>
					{/* Button List for larger screens */}
					<div className="hidden md:block space-y-2">
						{Object.keys(calculatorConfig).map((calcType) => (
							<button
								key={calcType}
								onClick={() => {
									setSelectedCalculator(calcType);
									setInputs({ input1: "", input2: "", input3: "" });
									setCalculated(null);
								}}
								className={`w-full text-left px-4 py-2 rounded transition duration-300 ${
									selectedCalculator === calcType
										? "bg-blue-600 text-white"
										: "bg-gray-100 text-gray-800 hover:bg-gray-200"
								}`}
							>
								{calcType}
							</button>
						))}
					</div>
				</div>
				{/* Right container: Calculator options */}
				<div className="w-full md:w-2/3 bg-white rounded-lg shadow-md p-6">
					{/* Outer Container for Aura Effect */}
					<div className="relative h-[500px]  bg-gray-100 rounded-lg shadow-lg">
						<h2 className="text-2xl font-bold mb-6 text-center text-gray-800 py-4">
							{calculatorConfig[selectedCalculator].title}
						</h2>

						<div className="space-y-4 mb-6 overflow-auto">
							{Object.entries(calculatorConfig[selectedCalculator])
								.slice(1, -1)
								.map(
									([key, value]) =>
										value.label && (
											<div key={key} className="flex flex-col">
												<label className="font-semibold text-gray-700 mb-1">
													{value.label}
												</label>
												<input
													type="number"
													placeholder={value.placeholder}
													value={inputs[key]}
													onChange={(e) => handleInputChange(e, key)}
													className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
												/>
											</div>
										)
								)}
						</div>
						{calculated && (
							<div className="mt-6 text-center">
								<p className="text-lg font-semibold text-gray-800">
									{calculatorConfig[selectedCalculator].resultLabel}{" "}
									<span className="text-blue-600">{calculated}</span>
								</p>
							</div>
						)}
						<div className="flex flex-col sm:flex-row justify-evenly items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-4">
							{!calculated ? (
								<button
									onClick={handleCalculate}
									className="w-full sm:w-auto bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition duration-300"
								>
									Calculate
								</button>
							) : (
								<>
									<button
										onClick={handleGetAIInsights}
										className="w-full sm:w-auto bg-green-600 text-white px-6 py-2 rounded hover:bg-green-700 transition duration-300"
									>
										Get AI Insights
									</button>
									<button
										onClick={handleCalculate}
										className="w-full sm:w-auto bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition duration-300"
									>
										Re-Calculate
									</button>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			<ToastContainer position="bottom-right" />
		</div>
	);
};

export default CalculatorPage;
