import React, { useEffect, useState } from "react";

const WelcomeHeader = () => {
  let [currSession, setCurrSession] = useState(null);
  let [userName, setUserName] = useState(null);

  function getCurrentPartOfDay() {
    const now = new Date();
    const hours = now.getHours();

    if (hours >= 5 && hours < 12) {
      return "morning";
    } else if (hours >= 12 && hours < 17) {
      return "afternoon";
    } else if (hours >= 17 || hours < 5) {
      return "evening";
    }
  }

  useEffect(() => {
    let timeOfDay = getCurrentPartOfDay();
    setCurrSession(timeOfDay);
    if (localStorage.getItem("userData")) {
      let email = JSON.parse(localStorage.getItem("userData")).email;
      setUserName(email.split("@")[0]);
    }
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div>
          <span
            className="block font-extrabold text-[#525f7f] leading-snug 
            text-[2.4vh] md:text-[3.6vh] capitalize font-sans"
          >
            Good {currSession}, <span className="capitalize">{userName}</span> 👋
          </span>
          <br />
          <span
            className="block font-medium text-[#525f7f] leading-snug 
            text-[2vh] md:text-[2.5vh] font-sans"
          >
            🚀 Connect your portfolio with AIFT and see valuable insights
          </span>
        </div>
      </div>
    </div>
  );
};

export default WelcomeHeader;
