import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../AuthContext";
import { ToastContainer, toast } from "react-toastify";
import Server from "../../api/Server";
import { useNavigate } from "react-router-dom";
import aiftAnalytics from "../../analytics/aiftAnalytics";
import { Briefcase, Bot, LogOut } from "lucide-react";

export default function UserProfileDropdown({ userPortfolioData }) {
	const [userEmail, setUserEmail] = useState("");
	const [showDropdown, setShowDropdown] = useState(false);
	const { logout } = useContext(AuthContext);
	const navigate = useNavigate();

	const gotoManagePortfolio = async () => {
		navigate("/manage-portfolio", {
			state: { userPortfolioDataFromTable: userPortfolioData },
		});
		await aiftAnalytics.actionCall(
			"managePortfolioPageFromDropdownClicked",
			"pageChange"
		);
	};

	const gotoAiAssist = async () => {
		navigate("/ai-assist");
		await aiftAnalytics.actionCall(
			"aiAssistPageFromDropdownClicked",
			"pageChange"
		);
	};

	const handleLogout = async () => {
		try {
			setShowDropdown(false);
			toast.success("Signing out...");
			let res = await Server.userLogout();
			if (res.data.code !== 200) {
				toast.error(res.data.message);
				return;
			} else {
				setTimeout(() => {
					logout();
				}, 1000);
			}
			navigate("/");
		} catch (error) {
			console.error("Logout error:", error);
		}
	};

	useEffect(() => {
		let userData = JSON.parse(localStorage.getItem("userData"));
		setUserEmail(userData.email);
	}, []);

	return (
		<div className="relative">
			<button
				onClick={() => setShowDropdown(!showDropdown)}
				className="w-10 h-10 rounded-full bg-blue-600 text-white flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
				aria-haspopup="true"
				aria-expanded={showDropdown}
			>
				<span className="sr-only">Open user menu</span>
				{userEmail.charAt(0).toUpperCase()}
			</button>

			{showDropdown && (
				<div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
					<div className="max-h-[calc(100vh-100px)] overflow-y-auto">
						<button
							onClick={gotoManagePortfolio}
							className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
						>
							<Briefcase
								className="mr-3 h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
							Manage portfolio
						</button>
						<button
							onClick={gotoAiAssist}
							className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
						>
							<Bot className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
							AI assist
						</button>
						<button
							onClick={handleLogout}
							className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
						>
							<LogOut
								className="mr-3 h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
							Logout
						</button>
					</div>
				</div>
			)}

			<ToastContainer position="top-right" />
		</div>
	);
}
