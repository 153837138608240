import React, { useEffect, useState } from "react";
import UnifiedCalculator from "./CalculatorType.jsx";

const CalculatorHeader = () => {
	const [selectedOption, setSelectedOption] = useState("");
	const [options, setOptions] = useState([]);

	const handleSelect = (event) => {
		setSelectedOption(event.target.value);
	};

	useEffect(() => {
		setOptions(["Sheet 1 - Mr. Aman Rawat"]);
	}, []);

	return (
		<div className="container mx-auto px-4">
			<div className="flex flex-col items-center justify-center min-h-[50vh] space-y-8">
				<h1 className="text-3xl md:text-5xl font-bold text-center text-gray-800">
					AI-Powered Financial Calculators
				</h1>
				<h2 className="text-xl md:text-2xl text-center text-gray-600">
					Unlock Financial Clarity with Intelligent Tools
				</h2>
				<div className="w-full max-w-md">
					<select
						value={selectedOption}
						onChange={handleSelect}
						className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
					>
						{options.map((option, index) => (
							<option key={index} value={option}>
								{option}
							</option>
						))}
					</select>
				</div>
			</div>

			<div className="mt-2">
				<UnifiedCalculator />
			</div>
		</div>
	);
};

export default CalculatorHeader;
