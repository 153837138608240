import axios from "axios";
let apiUrl = process.env.REACT_APP_AIFT_API_URL;

class aiftAnalyticsInstance {
  async actionCall(event_name, event_type) {
    const currentURL = window.location.href;
    if(currentURL.includes('http://')){
      return;
    }
          try {
            await axios.post(`${apiUrl}/events/save`, {
              user_id: JSON.parse(localStorage.getItem('userData')).email,
              event_name: event_name,
              event_type: event_type,
              product: "aift_website",
              source: currentURL,
              timeStamp: new Date()
            });
          }
          catch (error) {
            console.error("Analytics error");
          }
  }
}

const AIFTAnalytics = new aiftAnalyticsInstance();

export default AIFTAnalytics;