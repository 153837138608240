import React from "react";
import UserProfileDropdown from "../components/profileActions/UserProfileDropdown.jsx";
// import Integrations from "../components/integrations/Integrations.jsx";
import PortfolioData from "../components/integrations/PortfolioData.jsx";
import aiftAnalytics from "../analytics/aiftAnalytics.js";

import { LayoutDashboard } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ManagePortfolio = () => {
	const navigate = useNavigate();

	const goToDashboard = async () => {
		navigate("/home");
		await aiftAnalytics.actionCall("DashboardButtonClicked", "pageChange");
	};
	return (
		<div>
			<div className="bg-white shadow-md">
				<div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
					<div className="flex justify-between h-16 items-center font-sans">
						{/* Logo Section */}
						<div className="flex items-center">
							<img className="h-12 w-auto" src="/icons/AIFT.png" alt="Logo" />
						</div>

						{/* Dashboard and User Profile Section */}
						<div className="flex items-center">
							<button
								onClick={goToDashboard}
								className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out flex items-center"
							>
								{/* Adding LayoutDashboard icon */}
								<LayoutDashboard className="w-5 h-5 mr-2" />
								Dashboard
							</button>
							<UserProfileDropdown />
						</div>
					</div>
				</div>
			</div>

			<div
				style={{
					fontFamily: "'SF-Pro-Display', sans-serif",
					margin: "0.5rem",
					marginTop: "5vh",
				}}
			>
				<PortfolioData />
			</div>
		</div>
	);
};

export default ManagePortfolio;
