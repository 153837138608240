import React from 'react';

export default function Paginate({ postsPerPage, totalPosts, currentPage, paginate, previousPage, nextPage }) {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => {
    const totalPages = pageNumbers.length;
    const pagesToShow = 3; // Number of pages to show at a time
    const currentChunkStart = Math.floor((currentPage - 1) / pagesToShow) * pagesToShow + 1;
    const currentChunkEnd = Math.min(currentChunkStart + pagesToShow - 1, totalPages);

    const pageNumbersToShow = [];

    for (let i = currentChunkStart; i <= currentChunkEnd; i++) {
      pageNumbersToShow.push(i);
    }

    return pageNumbersToShow.map((number) => renderPageButton(number));
  };

  const renderPageButton = (number) => (
    <button
      key={number}
      onClick={() => paginate(number)}
      className={`px-3 py-2 rounded-md transition-colors duration-300 ${
        currentPage === number
          ? 'bg-blue-600 text-white font-bold'
          : 'bg-white text-blue-600 hover:bg-blue-100'
      }`}
    >
      {number}
    </button>
  );

  return (
    <nav className="flex justify-center items-center space-x-2 mt-8">
      <button
        onClick={previousPage}
        disabled={currentPage === 1}
        className={`px-4 py-2 rounded-md transition-colors duration-300 ${
          currentPage === 1
            ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
            : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
      >
        Previous
      </button>

      <div className="flex space-x-1">
        {renderPageNumbers()}
      </div>

      <button
        onClick={nextPage}
        disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
        className={`px-4 py-2 rounded-md transition-colors duration-300 ${
          currentPage === Math.ceil(totalPosts / postsPerPage)
            ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
            : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
      >
        Next
      </button>
    </nav>
  );
}
