import React, { createContext, useState, useEffect } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const login = (data) => {
		localStorage.setItem("accessToken", data.accessToken);
		localStorage.setItem("userData", JSON.stringify(data));
		setIsAuthenticated(true);
	};

	const logout = () => {
		for (let i = 0; i < localStorage.length; i++) {
			const key = localStorage.key(i);
			if (key) {
				localStorage.removeItem(key);
			}
		}
		localStorage.removeItem("accessToken");

		setIsAuthenticated(false);
	};

	useEffect(() => {
		const storedUserData = localStorage.getItem("accessToken");
		if (storedUserData) {
			setIsAuthenticated(true);
		}
	}, []);

	return (
		<AuthContext.Provider value={{ isAuthenticated, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
