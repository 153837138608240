import React, { useState, useEffect, useCallback } from "react";
import server from "../../api/Server";
import { toast } from "react-toastify";
import jsPDF from 'jspdf';
import aiftAnalytics from "../../analytics/aiftAnalytics";

const GenerateAiReport = ({portfolioData}) => {
    let [resultData, setResultData] = useState('');
    let [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    let [generateCount, setGenerateCount] = useState(0);
    let [idx, setIdx] = useState(0);
    let [typeWriterText, setTypeWriterText] = useState(``);
    const handleDownload = async () => {
      const doc = new jsPDF();
      doc.setFontSize(18);
      doc.text('Financial Report', 14, 22);
      doc.setFontSize(12);
    
      const plainText = getFormattedData(resultData, true);
      const lines = doc.splitTextToSize(plainText, 180);
    
      let y = 36;
      const pageHeight = doc.internal.pageSize.height;
      const lineHeight = 10;
    
      lines.forEach((line, index) => {
        if (y + lineHeight > pageHeight - 10) {
          doc.addPage();
          y = 20;
        }
        doc.text(line, 14, y);
        y += lineHeight;
      });
    
      doc.save('financial_report.pdf');
      await aiftAnalytics.actionCall('downloadGeneratedReportButtonClicked','ai');
    };
    const getFormattedData = (text, formatForPdf = false) => {
      if (formatForPdf) {
        return text
          .replace(/<h2>/g, '## ')
          .replace(/<\/h2>/g, '\n')
          .replace(/<strong>/g, '')
          .replace(/<\/strong>/g, '')
          .replace(/<li>/g, '- ')
          .replace(/<\/li>/g, '\n')
          .replace(/<br \/>/g, '\n')
          .replace(/<\/?[^>]+(>|$)/g, "");
      } else {
        return text
          .replace(/##\s/g, '<h3>')
          .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
          .replace(/\*\s(.*)/g, '<li>$1</li>')
          .replace(/(?:\r\n|\r|\n)/g, '<br />')
          .replace(/<\/li><br \/>/g, '</li>');
      }
    };
    const wait = (ms) => new Promise((res) => setTimeout(res, ms));

    const typeWriter = useCallback(async () => {
        if (idx < resultData.length) {
            setTypeWriterText((prev) => prev + resultData.charAt(idx));
            setIdx((prev) => prev + 1);
            await wait(10000);
        }
    }, [idx, resultData]);
    useEffect(() => {
        if (resultData && idx < resultData.length) {
            typeWriter();
        }
    }, [idx, resultData, typeWriter]);
    
    const fetchReport = async () => {
        try {
            setLoading(true);
            setError(null);
            setIdx(0);
            setTypeWriterText(``);
            setResultData(``);
            const promptData = portfolioData;
      console.log(prompt, 'prompt here');
            let resp = await server.generateAiReport(promptData, generateCount);
            console.log(resp);
            if(resp.data.code !== 200){
                toast.error(resp.data.msg);
                setError(resp.data.msg);
                setLoading(false);
                return;
            }
            setGenerateCount(++generateCount);
            let formattedText = getFormattedData(resp.data.data.reportData);
            setResultData(formattedText);
            setIdx(0);
            setLoading(false);
            await aiftAnalytics.actionCall('reportGenerateButtonClicked','ai');
        } catch (error) {
            setLoading(false);
            toast.error('Some internal server error occured');
            setError('An error occurred while fetching the report.');
        }
    }
    return (
        <div>
            <div style={{display: (resultData!=='') ? '' : 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh', overflowY: (resultData)?'scroll':'', margin: "3vh"}}>
              { !error && resultData==='' ? (
                <div style={{display: 'flex', flexDirection: (window.innerWidth>760)?'':'column', justifyContent: 'space-evenly', alignItems: 'center', width: '85%'}}>
                  <div>
                    <img src="/icons/AIFT.png" style={{height: (window.innerWidth>760)?"34vh":"15vh", marginBottom:(window.innerWidth>760)?'': '3vh'}} alt="" />
                  </div>
                  <div style={{width: '0.2vh', height: '40vh', backgroundColor: 'black', margin: '3vh', display: (window.innerWidth>760)?'':'none'}}></div>
                  <div style={{fontSize: '3vh', maxWidth: (window.innerWidth>760)?'19vw':'70vw'}}>
                  Unlock valuable insights with AI-generated  financial data reports. Transform your  raw data into actionable  intelligence with just a click.
                  </div>
                </div>
              ) : (
                resultData!=='' ? (
                  resultData && <pre style={{whiteSpace: "break-spaces"}} dangerouslySetInnerHTML={{ __html: typeWriterText }} />
                ) : (
                  <p>{error}</p>
                )
              ) }
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <button onClick={fetchReport} disabled={loading} style={{background: "#6f6fff", fontSize: "1.9vh", color: "white", width: "20vh", height: "4vh", border: 'none', borderRadius: '8px' ,cursor: "pointer"}}>
                {loading ? 'Generating...' : (generateCount === 0) ? 'Generate Report':'Regenerate'}
              </button>
              {
                (generateCount>=1) && (
                  <button onClick={handleDownload} style={{background: "#6f6fff", marginLeft: '2vh', fontSize: "1.9vh", color: "white", width: "20vh", height: "4vh", border: 'none', borderRadius: '8px' ,cursor: "pointer"}}>
                    Download
                  </button>
                )
              }
            </div>
        </div>
    )
}

export default GenerateAiReport;