import axios from "axios";
const apiUrl = process.env.REACT_APP_AIFT_API_URL;

class Server {
    async newUserRegister(email, password) {
        try {
            return await axios.post(`${apiUrl}/user/create`, {
                'email': email,
                'password': password
            });
        } catch (error) {
            console.log(error);
        }
    }
    async userLogin(email, password) {
        try {
            return await axios.post(`${apiUrl}/user/login`, {
                'email': email,
                'password': password
            });
        } catch (error) {
            console.log(error);
        }
    }
    async googleLogin(tokenId){
        try {
            return await axios.post(`${apiUrl}/user/google-login`, {
                tokenId
            })
        } catch (error) {
            
        }
    }
    async userLogout() {
        try {
            return await axios.post(`${apiUrl}/user/logout`, {});
        } catch (error) {
            console.log(error);
        }
    }
    async createManualStock(data) {
        try {
            return await axios.post(`${apiUrl}/portfolio/create-manual-stock`, { 
                email: JSON.parse(localStorage.getItem('userData')).email,
                portfolioData: data
            })
        } catch (error) {
            
        }
    }
    async updateManualStock(data, id) {
        try {
            return await axios.post(`${apiUrl}/portfolio/update-manual-stock`, {
                email: JSON.parse(localStorage.getItem('userData')).email,
                portfolioData: data,
                id: id
            })
        } catch (error) {
            
        }
    }
    async createStocksFromFile(data) {
        console.log(data);
        try {
            return await axios.post(`${apiUrl}/portfolio/integrate-excel-data`, { 
                email: JSON.parse(localStorage.getItem('userData')).email,
                formData: data
            })
        } catch (error) {
            
        }
    }
    async getUserPortfolio() {
        try {
            return await axios.post(`${apiUrl}/portfolio/get`, {
                email: JSON.parse(localStorage.getItem('userData')).email
            })
        } catch (error) {
            
        }
    }
    async deleteManualStock(idx){
        try {
            return await axios.post(`${apiUrl}/portfolio/delete-manual-stock`, {
                email: JSON.parse(localStorage.getItem('userData')).email,
                idx: idx
            })
        } catch (error) {
            
        }
    }
    async getDashboardDetails(){
        try {
            return await axios.post(`${apiUrl}/portfolio/get-dashboard-details`,{
                email: JSON.parse(localStorage.getItem('userData')).email
            });
        } catch (error) {
            
        }
    }
    async generateAiReport(prompt, count){
        try {
            return await axios.post(`${apiUrl}/ai/generate-financial-report`,{
                email: JSON.parse(localStorage.getItem('userData')).email,
                prompt,
                regenerate: (count === 0) ? false : true
            });
        } catch (error) {
            
        }
    }

    async generateStreamDataFromGemini(obj){
        try {
            return await axios.post(`${apiUrl}/ai/generate-stream-data-from-gemini`, {
                email: JSON.parse(localStorage.getItem('userData')).email,
                promptData: obj
            });
        } catch (error) {
            
        }
    }

    async saveNotificationDetails(stockName, operator, value){
        try {
            return await axios.post(`${apiUrl}/notify/save-notification-details`, {
                email: JSON.parse(localStorage.getItem('userData')).email,
                stockName,
                operator,
                value
            });
        } catch (error) {
            
        }
    }

    async getNotificationSettingsByStock(stockName){
        try {
            return await axios.post(`${apiUrl}/notify/get-notification-settings-by-stock`,{
                email: JSON.parse(localStorage.getItem('userData')).email,
                stockName
            })
        } catch (error) {
            
        }
    }

    async deleteNotificationSettingsByStock(stockName, operator, value){
        try {
            return await axios.post(`${apiUrl}/notify/delete-notification-settings-by-stock`,{
                email: JSON.parse(localStorage.getItem('userData')).email,
                stockName,
                operator,
                value
            })
        } catch (error) {
            
        }
    }

    async getNewsSentiments(){
        try {
            return await axios.post(`${apiUrl}/alphavantage/get-news-sentiment`,{})
        } catch (error) {
            
        }
    }
    async getTopGainers(){
        try {
            return await axios.post(`${apiUrl}/alphavantage/get-top-gainers`,{})
        } catch (error) {
            
        }
    }
    async getTopLosers(){
        try {
            return await axios.post(`${apiUrl}/alphavantage/get-top-losers`,{})
        } catch (error) {
            
        }
    }
    async getGraphData(){
        try {
            return await axios.post(`${apiUrl}/alphavantage/get-graph-data`, {})
        } catch (error) {
            
        }
    }
}

const server = new Server();

export default server;