import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsCircleFill } from "react-icons/bs";
import { FaChartLine, FaBell } from "react-icons/fa";

const icons = {
	stockAnalysisAI: "/icons/landingPage/portfolio.png",
	notificationSystem: "/icons/landingPage/notifications.png",
};

export default function Blog() {
	return (
		<div className="bg-[#f5f7fa] font-sans py-12 md:py-16 lg:py-20">
			<div className="max-w-[80rem] mx-auto px-4 sm:px-6 lg:px-8">
				<div className="text-center mb-12">
					<h2 className="text-2xl md:text-3xl font-extrabold pb-2 text-[#f5aa21]">
						Unique Selling Points
					</h2>
					<p className="text-3xl md:text-4xl lg:text-5xl font-black">
						AI Integration in Financial Sectors
					</p>
				</div>

				{/* Stock Analysis Using AI */}
				<div className="flex flex-col lg:flex-row items-center mb-12 transition-transform duration-300 cursor-pointer bg-gradient-to-r from-[#FCF8FB] to-[#F8E9F1] hover:scale-105 hover:shadow-lg rounded-lg overflow-hidden">
					{/* Content */}
					<div className="flex flex-col p-6 lg:p-8 w-full lg:w-1/3">
						<div className="text-3xl lg:text-4xl text-[#6D28D9] mb-4 text-center lg:text-left">
							Stock Analysis Using AI
						</div>
						<p className="text-lg text-[#6B7280] mb-6">
							Harness the power of AI to analyze stock data and receive tailored
							portfolio recommendations based on sophisticated algorithms.
						</p>
						<ul className="text-base text-[#6B7280]">
							<li className="flex items-center mb-3">
								<AiFillCheckCircle className="w-5 h-5 text-[#10B981] mr-3 flex-shrink-0" />
								<span className="font-bold">Advanced AI Integration</span>
							</li>
							<li className="flex items-start mb-3">
								<AiFillCheckCircle className="w-5 h-5 text-[#10B981] mr-3 mt-1 flex-shrink-0" />
								<span className="leading-tight font-bold">
									Comprehensive Stock Analysis: Historical Performance, Risk
									Evaluation and Portfolio Strategies
								</span>
							</li>
							<li className="flex items-center">
								<AiFillCheckCircle className="w-5 h-5 text-[#10B981] mr-3 flex-shrink-0" />
								<span className="font-bold">Real-time Insights</span>
							</li>
						</ul>
					</div>

					{/* Image with Creative Element */}
					<div className="flex flex-col items-center w-full lg:w-2/3 p-4 sm:p-6 lg:p-8 relative mt-8 lg:mt-0">
						<div className="absolute -top-4 sm:-top-6 left-1/2 transform -translate-x-1/2 bg-white rounded-full p-2 sm:p-3 md:p-4 shadow-lg z-10">
							<div className="text-sm sm:text-base md:text-xl lg:text-2xl font-bold text-[#333] flex items-center h-8 sm:h-10 md:h-12">
								<FaChartLine className="text-[#6D28D9] mr-2 w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6" />
								AI Tailored Portfolio
							</div>
						</div>
						<div className="relative text-center w-full bg-white rounded-lg shadow-lg p-2 sm:p-4">
							<img
								src={icons.stockAnalysisAI}
								alt="Stock Analysis Using AI"
								className="w-full h-auto"
							/>
						</div>
						<div className="absolute -bottom-3 sm:-bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-1 sm:space-x-2">
							<BsCircleFill className="text-[#6D28D9] animate-pulse w-2 h-2 sm:w-3 sm:h-3" />
							<BsCircleFill className="text-[#6D28D9] animate-pulse delay-100 w-2 h-2 sm:w-3 sm:h-3" />
							<BsCircleFill className="text-[#6D28D9] animate-pulse delay-200 w-2 h-2 sm:w-3 sm:h-3" />
						</div>
					</div>
				</div>

				{/* Notification System for Stock Updates */}
				<div className="flex flex-col lg:flex-row-reverse items-center mb-12 transition-transform duration-300 cursor-pointer bg-gradient-to-r from-[#FCF8FB] to-[#F8E9F1] hover:scale-105 hover:shadow-lg rounded-lg overflow-hidden">
					{/* Content */}
					<div className="flex flex-col p-6 lg:p-8 w-full lg:w-1/3">
						<div className="text-3xl lg:text-4xl text-purple-600 mb-4 text-center lg:text-left">
							Notification System for Stock Updates
						</div>
						<p className="text-lg text-gray-600 mb-6">
							Stay informed with real-time stock updates and personalized
							notifications to make timely investment decisions.
						</p>
						<ul className="text-base text-gray-600">
							<li className="flex items-center mb-3">
								<AiFillCheckCircle className="w-5 h-5 text-green-600 mr-3 flex-shrink-0" />
								<span className="font-bold">Real-time Updates</span>
							</li>
							<li className="flex items-center mb-3">
								<AiFillCheckCircle className="w-5 h-5 text-green-600 mr-3 flex-shrink-0" />
								<span className="font-bold">Customizable Alerts</span>
							</li>
							<li className="flex items-center">
								<AiFillCheckCircle className="w-5 h-5 text-green-600 mr-3 flex-shrink-0" />
								<span className="font-bold">Stock Watchlist Notifications</span>
							</li>
						</ul>
					</div>

					{/* Image with Creative Element */}
					<div className="flex flex-col items-center w-full lg:w-2/3 p-4 sm:p-6 lg:p-8 relative mt-8 lg:mt-0">
						<div className="absolute -top-4 sm:-top-6 left-1/2 transform -translate-x-1/2 bg-white rounded-full p-2 sm:p-3 md:p-4 shadow-lg z-10">
							<div className="text-sm sm:text-base md:text-xl lg:text-2xl font-bold text-[#333] flex items-center h-8 sm:h-10 md:h-12">
								<FaBell className="text-purple-600 mr-2 w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6" />
								AI Driven Alerts
							</div>
						</div>
						<div className="relative text-center w-full bg-white rounded-lg shadow-lg p-2 sm:p-4">
							<img
								src={icons.notificationSystem}
								alt="Notification System for Stock Updates"
								className="w-full h-auto"
							/>
						</div>
						<div className="absolute -bottom-3 sm:-bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-1 sm:space-x-2">
							<BsCircleFill className="text-purple-600 animate-pulse w-2 h-2 sm:w-3 sm:h-3" />
							<BsCircleFill className="text-purple-600 animate-pulse delay-100 w-2 h-2 sm:w-3 sm:h-3" />
							<BsCircleFill className="text-purple-600 animate-pulse delay-200 w-2 h-2 sm:w-3 sm:h-3" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
