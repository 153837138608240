import React from "react";
import { FaEnvelope, FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { ImWhatsapp } from "react-icons/im";

export default function Contact() {
	const sendEmail = () => {
		window.open("mailto:tanvit@ai-ft.in");
	};

	return (
		<div className="min-h-[50vh] flex items-center justify-center p-4 font-sans bg-gray-50">
			<div className="w-full max-w-7xl bg-white text-gray-800 rounded-lg shadow-lg p-6 md:p-8 lg:grid lg:grid-cols-2 lg:gap-8">
				<div>
					<h1 className="text-4xl md:text-5xl font-bold">Let&apos;s Talk</h1>
					<p className="text-sm text-gray-600 mt-3">
						Want to give any feedback or want to join our team ,Then reach out{" "}
						<br /> we&apos;d love to hear about.
					</p>
					<div className="mt-8">
						<h2 className="text-lg font-bold">Email</h2>
						<ul className="mt-1">
							<li>
								<button
									onClick={sendEmail}
									className="h-10 w-10 rounded-full flex items-center justify-center hover:bg-gray-100 transition-colors"
								>
									<FaEnvelope size={20} className="text-blue-600" />
								</button>
							</li>
						</ul>
					</div>
					<div className="mt-8">
						<h2 className="text-lg font-bold">Socials</h2>
						<ul className="flex mt-1 space-x-4">
							<li>
								<a
									href="https://www.instagram.com/aift_main/"
									target="_blank"
									rel="noopener noreferrer"
									className="h-10 w-10 rounded-full flex items-center justify-center hover:bg-gray-100 transition-colors"
								>
									<FaInstagram size={20} className="text-blue-600" />
								</a>
							</li>
							<li>
								<a
									href="https://www.linkedin.com/company/aift-ai"
									target="_blank"
									rel="noopener noreferrer"
									className="h-10 w-10 rounded-full flex items-center justify-center hover:bg-gray-100 transition-colors"
								>
									<FaLinkedinIn size={20} className="text-blue-600" />
								</a>
							</li>
							<li>
								<a
									href="https://wa.me/7510005269"
									target="_blank"
									rel="noopener noreferrer"
									className="h-10 w-10 rounded-full flex items-center justify-center hover:bg-gray-100 transition-colors"
								>
									<ImWhatsapp size={20} className="text-blue-600" />
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="mt-12 lg:mt-0 lg:pl-8 lg:border-l border-gray-200">
					<h2 className="text-lg font-bold">Message Us</h2>
					<form className="mt-3 space-y-4">
						<div>
							<input
								type="text"
								placeholder="Name"
								className="w-full rounded-lg p-3 bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-blue-600 border border-gray-200"
							/>
						</div>
						<div>
							<input
								type="email"
								placeholder="Email"
								className="w-full rounded-lg p-3 bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-blue-600 border border-gray-200"
							/>
						</div>
						<div>
							<textarea
								rows={4}
								placeholder="Message"
								className="w-full rounded-lg p-3 bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-blue-600 border border-gray-200 shadow-sm"
							></textarea>
						</div>
						<button
							type="submit"
							className="bg-blue-600 text-white border-none rounded-lg px-6 py-3 text-base cursor-pointer hover:bg-blue-700 transition-colors"
						>
							Send Message
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}
