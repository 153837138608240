import React, { useCallback, useEffect, useState } from "react";
import server from "../../api/Server";
import { toast } from "react-toastify";
import aiftAnalytics from "../../analytics/aiftAnalytics";
import { Trash2 } from "lucide-react";

const NotifyFilterModal = ({ stockName, closeModal }) => {
	const [loading, setLoading] = useState(false);
	const [operator, setOperator] = useState("{{operator_type}}");
	const [stockValue, setStockValue] = useState("{{stock_value}}");
	const [stock, setStock] = useState("");
	const [previousSettings, setPreviousSettings] = useState([]);

	const handleSave = async () => {
		try {
			if (operator.includes("{{")) {
				toast.error("Operator not selected!");
				return;
			}
			if (stockValue === "") {
				toast.error("Expected value of stock is empty!");
				return;
			}
			setLoading(true);
			let resp = await server.saveNotificationDetails(
				stock,
				operator,
				stockValue
			);
			console.log(resp);
			toast.success(resp.data.msg);
			await aiftAnalytics.actionCall(
				"savedNotificationSetting",
				"notifications"
			);
		} catch (error) {
			setLoading(false);
		}
		setLoading(false);
		closeModal(true);
	};

	const handleDeleteSetting = async (item) => {
		try {
			setLoading(true);
			let res = await server.deleteNotificationSettingsByStock(
				item.stockName,
				item.operator,
				item.value
			);
			console.log(res);
			if (res.data.code !== 200) {
				setLoading(false);
				toast.error(res.data.msg);
				return;
			}
			setPreviousSettings(res.data.data);
			setLoading(false);
			await aiftAnalytics.actionCall(
				"deletedNotificationSetting",
				"notifications"
			);
		} catch (error) {
			setLoading(false);
			toast.error("Some error occurred");
		}
	};

	const getNotificationSettingsData = useCallback(async () => {
		try {
			let res = await server.getNotificationSettingsByStock(stock);
			console.log(res);
			if (res.data.code !== 200) {
				setLoading(false);
				return;
			}
			setPreviousSettings(res.data.data);
		} catch (error) {
			setLoading(false);
		}
		setLoading(false);
	}, [stock]);

	useEffect(() => {
		setLoading(true);
		console.log(stockName);
		setStock(stockName);
		getNotificationSettingsData();
	}, [stockName, getNotificationSettingsData]);

	return (
		<div className="bg-white p-6 rounded-lg shadow-lg  mx-auto">
			<h2 className="text-2xl font-bold mb-4">Notification Settings</h2>
			{loading ? (
				<div className="flex justify-center items-center h-64">
					<div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
				</div>
			) : (
				<>
					{previousSettings.length !== 0 && (
						<div className="mb-6">
							<h3 className="text-lg font-semibold mb-2">
								Pre-configured Settings
							</h3>
							<div className="max-h-40 overflow-y-auto">
								{previousSettings.map((item, idx) => (
									<div
										key={idx}
										className="flex justify-between items-center bg-gray-100 p-3 rounded-md mb-2"
									>
										<p className="text-sm">
											Value of{" "}
											<span className="font-medium">{item.stockName}</span> will
											be {item.operator} {item.value}.
										</p>
										<button
											onClick={() => handleDeleteSetting(item, idx)}
											className="text-red-500 hover:text-red-700"
										>
											<Trash2 size={18} />
										</button>
									</div>
								))}
							</div>
						</div>
					)}

					<div className="mb-6">
						<h3 className="text-lg font-semibold mb-2">
							Configure New Notification
						</h3>
						<p className="mb-4">
							You have selected{" "}
							<span className="text-blue-600 font-semibold">{stock}</span> to
							configure for notifications.
						</p>

						<div className="mb-4">
							<label className="block text-sm font-medium text-gray-700 mb-2">
								Select Operator
							</label>
							<div className="flex flex-wrap gap-2">
								{["greater than", "less than", "equal to"].map((op) => (
									<button
										key={op}
										onClick={() => setOperator(op)}
										className={`px-3 py-2 rounded-md text-sm ${
											operator === op
												? "bg-blue-500 text-white"
												: "bg-gray-200 text-gray-800 hover:bg-gray-300"
										}`}
									>
										{op}
									</button>
								))}
							</div>
						</div>

						<div className="mb-4">
							<label
								htmlFor="stockValue"
								className="block text-sm font-medium text-gray-700 mb-2"
							>
								Stock Value
							</label>
							<input
								id="stockValue"
								type="text"
								className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
								onChange={(e) => setStockValue(e.target.value)}
							/>
						</div>

						<div className="bg-blue-100 border-l-4 border-blue-500 p-4 mb-4">
							<p className="text-blue-700">
								You will be notified when the value of{" "}
								<span className="font-semibold">{stock}</span> is{" "}
								<span className="font-semibold">{operator}</span>{" "}
								<span className="font-semibold">{stockValue}</span>.
							</p>
						</div>
					</div>

					<div className="flex justify-center">
						<button
							onClick={handleSave}
							className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
						>
							Save
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export default NotifyFilterModal;
