import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import aiftAnalytics from "../../analytics/aiftAnalytics";
import { Bell, X, Settings, Clock } from "lucide-react";

const Notifications = ({ notifications }) => {
	const [showNotifications, setShowNotifications] = useState(false);
	const [allNotifs, setAllNotifs] = useState([]);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
	const navigate = useNavigate();
	const notificationRef = useRef(null);

	const gotoNotificationConfigure = async () => {
		navigate("/ai-assist", { state: { showNotification: true } });
		await aiftAnalytics.actionCall(
			"manageNotificationIconClicked",
			"notifications"
		);
	};

	const getTimeAgo = (timestamp) => {
		const now = new Date();
		const then = new Date(timestamp);
		const seconds = Math.floor((now.getTime() - then.getTime()) / 1000);

		if (seconds < 0) return "in the future";

		const intervals = [
			{ label: "year", seconds: 31536000 },
			{ label: "month", seconds: 2592000 },
			{ label: "day", seconds: 86400 },
			{ label: "hour", seconds: 3600 },
			{ label: "minute", seconds: 60 },
			{ label: "second", seconds: 1 },
		];

		for (let i = 0; i < intervals.length; i++) {
			const interval = Math.floor(seconds / intervals[i].seconds);
			if (interval >= 1) {
				return (
					interval +
					" " +
					intervals[i].label +
					(interval > 1 ? "s" : "") +
					" ago"
				);
			}
		}
	};

	const handleShowNotifications = async (val) => {
		setShowNotifications(val);
		await aiftAnalytics.actionCall(
			val ? "openNotificationsClicked" : "closedNotificationsClicked",
			"notifications"
		);
	};

	useEffect(() => {
		setAllNotifs(notifications);

		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		const handleClickOutside = (event) => {
			if (
				notificationRef.current &&
				!notificationRef.current.contains(event.target)
			) {
				setShowNotifications(false);
			}
		};

		window.addEventListener("resize", handleResize);
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			window.removeEventListener("resize", handleResize);
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [notifications]);

	const renderNotifications = () => (
		<div className="bg-white rounded-lg shadow-xl overflow-hidden">
			<div className="p-4 bg-blue-600 text-white flex justify-between items-center">
				<h2 className="text-lg font-semibold">Notifications</h2>
				<div className="flex items-center space-x-2">
					<button
						onClick={gotoNotificationConfigure}
						className="p-1 hover:bg-blue-700 rounded-full"
						aria-label="Configure notifications"
					>
						<Settings className="h-5 w-5" />
					</button>
					<button
						onClick={() => handleShowNotifications(false)}
						className="p-1 hover:bg-blue-700 rounded-full"
						aria-label="Close notifications"
					>
						<X className="h-5 w-5" />
					</button>
				</div>
			</div>
			<div className="max-h-96 overflow-y-auto">
				{allNotifs && allNotifs.length > 0 ? (
					allNotifs.map((notif, idx) => (
						<div
							key={idx}
							className="p-4 border-b hover:bg-gray-50 transition-colors duration-200"
						>
							<h3 className="font-semibold text-gray-800">{notif.name}</h3>
							<p className="text-sm text-gray-600 mt-1">{notif.desc}</p>
							<div className="flex items-center justify-end mt-2 text-xs text-gray-500">
								<Clock className="h-3 w-3 mr-1" />
								{getTimeAgo(notif.time)}
							</div>
						</div>
					))
				) : (
					<div className="p-4 text-center text-gray-500">No notifications</div>
				)}
			</div>
		</div>
	);

	return (
		<div className="relative" ref={notificationRef}>
			<button
				onClick={() => handleShowNotifications(!showNotifications)}
				className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
				aria-label="Notifications"
			>
				<Bell className="h-6 w-6 text-gray-600" />
			</button>
			{showNotifications &&
				(isMobile ? (
					<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
						<div className="w-full max-w-sm mx-4">{renderNotifications()}</div>
					</div>
				) : (
					<div className="absolute right-0 mt-2 w-80 z-50">
						{renderNotifications()}
					</div>
				))}
		</div>
	);
};

export default Notifications;
