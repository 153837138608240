import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginSignupPage from "./pages/LoginSignupPage";
import ManagePortfolio from "./pages/ManagePortfolio.jsx";
import AiAssist from "./pages/AiAssist.jsx";
import CalculatorsPage from "./pages/CalculatorsPage.jsx";
import { AuthProvider } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import LandingPage from "./pages/Landingpage.jsx";

const App = () => {
	return (
		<AuthProvider>
			<Router>
				<Routes>
					<Route
						path="/"
						element={
							<PublicRoute>
								<LandingPage />
							</PublicRoute>
						}
					/>

					<Route
						path="/home"
						element={
							<ProtectedRoute>
								<HomePage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/manage-portfolio"
						element={
							<ProtectedRoute>
								<ManagePortfolio />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/ai-assist"
						element={
							<ProtectedRoute>
								<AiAssist />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/calculators"
						element={
							<ProtectedRoute>
								<CalculatorsPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/login"
						element={
							<PublicRoute>
								<LoginSignupPage />
							</PublicRoute>
						}
					/>
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</Router>
		</AuthProvider>
	);
};

export default App;
