import React, { useEffect, useState, useCallback } from "react";
import { BarChart2, Bell, Send } from "lucide-react";
import TimeSeriesGraph from "../graphs/TimeSeriesGraph.jsx";
import NotifyFilterModal from "./NotifyFilterModal.jsx";
import server from "../../api/Server";
import aiftAnalytics from "../../analytics/aiftAnalytics.js";

export default function MultiselectQuery({ showNotif = false }) {
	const [selectedOption, setSelectedOption] = useState("Stock");
	const [userPortfolioData, setUserPortfolioData] = useState([]);
	const [portfolioType, setPortfolioType] = useState("");
	const [selectedStockOption, setSelectedStockOption] = useState("");
	const [loading, setLoading] = useState(false);
	const [geminiData, setGeminiData] = useState("");
	const [openNotifyFilterModal, setOpenNotifyFilterModal] = useState(false);
	const [idx, setIdx] = useState(0);
	const [typeWriterText, setTypeWriterText] = useState("");
	const [isGraphModalOpen, setIsGraphModalOpen] = useState(false);

	const handleSelectStock = async (event) => {
		const value = event.target.value;
		setSelectedStockOption(value);
		await aiftAnalytics.actionCall(
			`${value}StockSelectedFromDropdown`,
			"notifications"
		);
	};

	const numericDateToYyyyMmDd = (numericDate) => {
		const baseDate = new Date(Date.UTC(1900, 0, 1));
		const excelOffset = numericDate - 1;
		const actualDate = new Date(
			baseDate.getTime() + excelOffset * 24 * 60 * 60 * 1000
		);
		const year = actualDate.getUTCFullYear();
		const month = String(actualDate.getUTCMonth() + 1).padStart(2, "0");
		const day = String(actualDate.getUTCDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	};

	const handleOpeNotifyFilter = async () => {
		setOpenNotifyFilterModal(true);
		setGeminiData("");
		await aiftAnalytics.actionCall(
			"addNotificationFilterButtonClicked",
			"notifications"
		);
	};

	const getUserData = useCallback(async () => {
		let data = await server.getUserPortfolio();
		if (data.data && data.data.code === 200) {
			let portfolioData = data.data.data.portfolioData.map((item) => {
				let newItem = { ...item };
				for (let key in newItem) {
					if (
						typeof newItem[key] === "number" &&
						newItem[key] > 40000 &&
						key.toLowerCase().includes("date")
					) {
						newItem[key] = numericDateToYyyyMmDd(newItem[key]);
					}
				}
				return newItem;
			});
			setUserPortfolioData(portfolioData);
			setPortfolioType(data.data.data.portfolioType);
			if (data.data.data.portfolioType === "manualStockAdd") {
				setSelectedStockOption(portfolioData[0].stockName);
			} else {
				setSelectedStockOption(portfolioData[0]["Stock Name"]);
			}
		}
	}, []);

	const getFormattedData = (formattedText) => {
		return formattedText
			.replace(/##\s/g, '<h2 class="text-xl font-bold mb-2">')
			.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
			.replace(/\*\s(.*)/g, "<li>$1</li>")
			.replace(/(?:\r\n|\r|\n)/g, "<br />")
			.replace(/<\/li><br \/>/g, "</li>");
	};

	const wait = (ms) => new Promise((res) => setTimeout(res, ms));

	const typeWriter = useCallback(async () => {
		if (idx < geminiData.length) {
			setTypeWriterText((prev) => prev + geminiData.charAt(idx));
			setIdx((prev) => prev + 1);
			await wait(10);
		}
	}, [idx, geminiData]);

	useEffect(() => {
		if (geminiData && idx < geminiData.length) {
			typeWriter();
		}
	}, [idx, geminiData, typeWriter]);

	const handleGenerateResponse = async () => {
		setIdx(0);
		setGeminiData("");
		setTypeWriterText("");
		let idx = 0;
		for (let i = 0; i < userPortfolioData.length; i++) {
			if (
				portfolioType === "manualStockAdd" &&
				userPortfolioData[i].stockName === selectedStockOption
			) {
				idx = i;
			} else if (userPortfolioData[i]["Stock Name"] === selectedStockOption) {
				idx = i;
			}
		}
		try {
			setOpenNotifyFilterModal(false);
			setLoading(true);
			let resp = await server.generateStreamDataFromGemini(
				userPortfolioData[idx]
			);
			if (resp.data.code !== 200) {
				setLoading(false);
			}
			if (resp.data.data.data) {
				let formated = getFormattedData(resp.data.data.data);
				setGeminiData(formated);
				setIdx(0);
				await aiftAnalytics.actionCall(
					`${selectedStockOption}AskAiButtonClicked`,
					"ai"
				);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		getUserData();
		if (showNotif) {
			setOpenNotifyFilterModal(true);
		}
	}, [getUserData, showNotif]);

	return (
		<div className="container mx-auto px-4 py-8">
			<div className="flex flex-col items-center justify-center min-h-[60vh] space-y-8">
				<h1 className="text-4xl md:text-5xl font-bold text-center">
					Actionable Insights with AI
				</h1>
				<h2 className="text-xl md:text-2xl text-center text-gray-600">
					Configure your data objects to generate insights!
				</h2>
				<div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full max-w-md">
					<select
						value={selectedOption}
						onChange={(e) => setSelectedOption(e.target.value)}
						className="w-full md:w-1/2 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
					>
						<option value="Stock">Stock</option>
					</select>
					<select
						value={selectedStockOption}
						onChange={handleSelectStock}
						className="w-full md:w-1/2 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
					>
						{userPortfolioData.map((opt, idx) => (
							<option
								key={idx}
								value={
									portfolioType === "manualStockAdd"
										? opt.stockName
										: opt["Stock Name"]
								}
							>
								{portfolioType === "manualStockAdd"
									? opt.stockName
									: opt["Stock Name"]}
							</option>
						))}
					</select>
				</div>
				<div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
					<button
						onClick={handleGenerateResponse}
						className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 flex items-center justify-center"
					>
						<Send className="w-4 h-4 mr-2" />
						Ask AI
					</button>
					<button
						onClick={handleOpeNotifyFilter}
						className="px-6 py-2 text-blue-600 border border-blue-600 rounded-md hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 flex items-center justify-center"
					>
						<Bell className="w-4 h-4 mr-2" />
						Add Notification Filter
					</button>
				</div>
			</div>

			{(geminiData !== "" || loading) && (
				<div className="mt-8 bg-gray-100 rounded-lg p-4">
					<div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
						{loading ? (
							<div className="w-full flex justify-center items-center">
								<img
									src="/icons/loadingRipple.gif"
									className="h-16"
									alt="Loading"
								/>
							</div>
						) : (
							<>
								<div className="w-full md:w-1/2 bg-white rounded-lg shadow-md p-4">
									<button
										onClick={() => setIsGraphModalOpen(true)}
										className="w-full md:hidden mb-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 flex items-center justify-center"
									>
										<BarChart2 className="w-4 h-4 mr-2" />
										View Graph
									</button>
									<div className="hidden md:block">
										<TimeSeriesGraph />
									</div>
								</div>
								<div className="w-full md:w-1/2 bg-white rounded-lg shadow-md p-4 h-[45vh] overflow-y-auto">
									<div dangerouslySetInnerHTML={{ __html: typeWriterText }} />
								</div>
							</>
						)}
					</div>
				</div>
			)}

			{openNotifyFilterModal && (
				<div className="mt-8 bg-gray-100 rounded-lg p-4">
					<NotifyFilterModal
						stockName={selectedStockOption}
						closeModal={() => setOpenNotifyFilterModal(false)}
					/>
				</div>
			)}

			{isGraphModalOpen && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
					<div className="bg-white p-4 rounded-lg w-full max-w-md">
						<TimeSeriesGraph />
						<button
							onClick={() => setIsGraphModalOpen(false)}
							className="mt-4 w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200"
						>
							Close
						</button>
					</div>
				</div>
			)}
		</div>
	);
}
