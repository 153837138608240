import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../api/Server";
import GenerateAiReport from "../ai/GenerateAiReport";
import aiftAnalytics from "../../analytics/aiftAnalytics";
import { PlusCircle, RefreshCw, X, AlertCircle } from "lucide-react";

export default function DashboardTable() {
	const navigate = useNavigate();
	const [userPortfolioData, setUserPortfolioData] = useState([]);
	const [columns, setColumns] = useState([
		{ key: "stockName", value: "Stock Name", width: "18%" },
		{ key: "stocksBought", value: "Stocks Bought", width: "18%" },
		{ key: "pricePerStock", value: "Price Per Stock", width: "18%" },
		{ key: "purchasedDate", value: "Purchased Date", width: "18%" },
		{ key: "tradeFees", value: "Trade Fees", width: "18%" },
	]);
	const [loading, setLoading] = useState(false);
	const [openAiReportModal, setOpenAiReportModal] = useState(false);

	const handleGotoManageDashboard = () => {
		navigate("/manage-portfolio");
	};

	const numericDateToYyyyMmDd = (numericDate) => {
		const baseDate = new Date(Date.UTC(1900, 0, 1));
		const excelOffset = numericDate - 1;
		const actualDate = new Date(
			baseDate.getTime() + excelOffset * 24 * 60 * 60 * 1000
		);
		const year = actualDate.getUTCFullYear();
		const month = String(actualDate.getUTCMonth() + 1).padStart(2, "0");
		const day = String(actualDate.getUTCDate()).padStart(2, "0");

		return `${day}-${month}-${year}`;
	};

	const handleOpenAiReportModal = async () => {
		setOpenAiReportModal(true);
		await aiftAnalytics.actionCall("reportModalOpenButtonClicked", "ai");
	};

	const getUserData = useCallback(async () => {
		setLoading(true);
		const lastRefreshed = localStorage.getItem("lastRefreshed");
		const now = new Date();

		if (
			lastRefreshed &&
			localStorage.getItem("portfolioData") &&
			localStorage.getItem("portfolioColumns")
		) {
			const lastRefreshedDate = new Date(JSON.parse(lastRefreshed));
			const timeDiff = (now - lastRefreshedDate) / (1000 * 60 * 60);

			if (timeDiff < 24) {
				const cachedData = JSON.parse(localStorage.getItem("portfolioData"));
				const cachedColumns = JSON.parse(
					localStorage.getItem("portfolioColumns")
				);
				setUserPortfolioData(cachedData);
				setColumns(cachedColumns);
				setLoading(false);
				return;
			}
		}
		const data = await server.getUserPortfolio();
		if (data.data && data.data.code === 200) {
			const portfolioData = data.data.data.portfolioData.map((item) => {
				const newItem = { ...item };
				for (let key in newItem) {
					if (
						typeof newItem[key] === "number" &&
						newItem[key] > 40000 &&
						key.toLowerCase().includes("date")
					) {
						newItem[key] = numericDateToYyyyMmDd(newItem[key]);
					}
				}
				return newItem;
			});

			setUserPortfolioData(portfolioData);
			localStorage.setItem("portfolioData", JSON.stringify(portfolioData));
			localStorage.setItem("lastRefreshed", JSON.stringify(now));

			if (data.data.data.portfolioType === "excelSheetData") {
				const cols = Object.keys(portfolioData[0]).map((key) => ({
					key: key,
					value:
						key.charAt(0).toUpperCase() +
						key.slice(1).replace(/([A-Z])/g, " $1"),
					width: "10%",
				}));
				setColumns(cols);
				localStorage.setItem("portfolioColumns", JSON.stringify(cols));
			}
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		getUserData();
	}, [getUserData]);

	return (
		<div className="container mx-auto px-4 py-8">
			<div className="bg-white rounded-xl shadow-lg p-6">
				<div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
					{/* Title with responsive text size */}
					<h2 className="text-xl md:text-2xl font-bold text-gray-800">
						Connected Data
					</h2>

					{/* Button with responsive width and spacing */}
					<button
						onClick={
							userPortfolioData.length === 0 ? null : handleOpenAiReportModal
						} // Disable the button if no data
						className={`w-full md:w-auto px-4 py-2 rounded-lg transition-colors duration-300 flex items-center justify-center ${
							userPortfolioData.length === 0
								? "bg-gray-300 text-gray-400 cursor-not-allowed" // Inactive style
								: "bg-blue-600 text-white hover:bg-blue-700" // Active style
						}`}
						disabled={userPortfolioData.length === 0} // Disable the button when there's no data
					>
						<AlertCircle className="w-5 h-5 mr-2" />
						<span className="text-sm md:text-base">
							Generate AI Report
						</span>{" "}
						{/* Responsive text size */}
					</button>
				</div>

				{userPortfolioData.length > 0 && (
					<div className="mb-6 bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-center">
						<div className="flex-grow">
							<h3 className="text-lg font-semibold text-blue-800 mb-2">
								Generate Your Personalized Financial Report
							</h3>
							<p className="text-blue-600">
								Click the "Generate AI Report" button to create a customized
								analysis of your portfolio data.
							</p>
						</div>
					</div>
				)}

				{loading ? (
					<div className="flex justify-center items-center h-64">
						<RefreshCw className="w-12 h-12 text-blue-500 animate-spin" />
					</div>
				) : !userPortfolioData || userPortfolioData.length === 0 ? (
					<div className="flex flex-col items-center justify-center h-64">
						<img src="/icons/noData.png" className="h-20 mb-4" alt="No data" />
						<h3 className="text-xl font-semibold text-gray-700 mb-4">
							No data to show here
						</h3>
						<button
							onClick={handleGotoManageDashboard}
							className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
						>
							<PlusCircle className="w-5 h-5 mr-2" />
							Add Data
						</button>
					</div>
				) : (
					<div className="overflow-x-auto">
						<table className="w-full">
							<thead>
								<tr>
									{columns.map((col, idx) => (
										<th
											key={idx}
											className="px-4 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
										>
											{col.value}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{userPortfolioData.map((data, index) => (
									<tr
										key={index}
										className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
									>
										{columns.map((col, idx) => (
											<td
												key={idx}
												className="px-4 py-2 whitespace-nowrap text-sm text-gray-700"
											>
												{data[col.key]}
											</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
			</div>

			{openAiReportModal && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
					<div className="bg-white rounded-lg w-full max-w-4xl max-h-[80vh] overflow-auto">
						<div className="p-6">
							<div className="flex justify-between items-center mb-4">
								<h2 className="text-2xl font-bold text-gray-800">
									Generate Your Financial Report
								</h2>
								<button
									onClick={() => setOpenAiReportModal(false)}
									className="text-gray-400 hover:text-gray-600"
								>
									<X className="w-6 h-6" />
								</button>
							</div>
							<GenerateAiReport portfolioData={userPortfolioData} />
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
