import React from "react";
import { Clock, Zap, Target, Users } from "lucide-react";

export default function FeaturesContent() {
	const aiSolutionPoints = [
		{
			icon: <Clock className="h-6 w-6" />,
			text: "Save Time: Automate routine tasks like data entry, portfolio management, Report writing, Stock research and many others",
			badge: "Efficiency",
		},
		{
			icon: <Target className="h-6 w-6" />,
			text: "Reduce Errors: Minimize human error with our advanced automation technology.",
			badge: "Accuracy",
		},
		{
			icon: <Zap className="h-6 w-6" />,
			text: "Increase Efficiency: Streamline your workflow and improve overall productivity.",
			badge: "Productivity",
		},
		{
			icon: <Users className="h-6 w-6" />,
			text: "Enhance Client Experience: Provide a more personalized and efficient service to your clients.",
			badge: "Client Satisfaction",
		},
	];

	return (
		<div
			className={`flex flex-col md:flex-row justify-between p-4 md:p-10 max-w-full mx-auto min-h-[70vh] bg-gray-100 `}
		>
			<div className="flex-1 md:mr-8 mb-8 md:mb-0 flex flex-col items-center justify-center">
				<div className="w-full aspect-video relative rounded-lg overflow-hidden shadow-lg">
					<iframe
						src="https://www.youtube.com/embed/TpYdf5Ene1M?theme=dark&autohide=0&cc_load_policy=1&modestbranding=1&fs=1&rel=0&iv_load_policy=3&mute=0&loop=1&playlist=TpYdf5Ene1M"
						allow="autoplay; encrypted-media"
						allowFullScreen
						className="absolute top-0 left-0 w-full h-full border-none"
						title="Video preview"
					></iframe>
				</div>
				<p className="text-center italic text-gray-500 text-sm mt-4 px-4">
					Watch our video to see how AI can transform your business
				</p>
			</div>
			<div className="flex-1 md:ml-8 bg-gray-100 rounded-xl shadow-[inset_-12px_-12px_24px_#ffffff,inset_12px_12px_24px_#d1d1d1] p-8">
				<div className="flex justify-center mb-6 text-center">
					<h2 className="text-3xl md:text-4xl font-bold text-gray-800 font-sans">
						The <span className="text-[rgb(119,0,70)]">benefits</span> your{" "}
						<span className="text-[rgb(119,0,70)]">business</span> gets
					</h2>
				</div>
				<p className="text-lg md:text-xl leading-relaxed mb-8 text-center text-gray-600 font-sans">
					Designed for businesses of all sizes, our AI solutions help you save
					time and money that can be spent on more vital elements of your
					business. Explore the benefits below.
				</p>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
					{aiSolutionPoints.map((point, index) => (
						<div
							key={index}
							className="bg-white p-6 rounded-lg shadow-md transition-transform duration-300 hover:-translate-y-1"
						>
							<div className="flex items-center mb-4">
								<div className="text-2xl mr-4 text-[rgb(119,0,70)] bg-[rgba(119,0,70,0.1)] p-2 rounded-full">
									{point.icon}
								</div>
								<span className="font-bold text-xl text-gray-800 font-sans">
									{point.badge}
								</span>
							</div>
							<p className="text-base text-gray-600 font-sans">{point.text}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
