import React, { useState, useRef, useEffect } from "react";
import server from "../../api/Server";
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from "xlsx";
import aiftAnalytics from "../../analytics/aiftAnalytics";

const AddStockDataModal = ({close, stockType, toEdit}) => {
    let [stockName, setStockName] = useState(null);
    let [stocksBought, setStocksBought] = useState(null);
    let [pricePerStock, setPricePerStock] = useState(null);
    let [purchasedDate, setPurchasedDate] = useState(null);
    let [tradeFees, setTradeFees] = useState(null);
    let [id, setId] = useState(null);
    let [loading, setLoading] = useState(false);
    const handleSaveData = async () => {
        if(!stockName || !stocksBought || !pricePerStock || !purchasedDate || !tradeFees){
            toast.error('All fields are required!');
            return;
        }
        try {
            setLoading(true);
            let obj = {
                stockName, stocksBought, pricePerStock, purchasedDate, tradeFees
            };
            let response;
            if(id!=null){
                toast.success('Updating stock details');
                response = await server.updateManualStock(obj, id);
                await aiftAnalytics.actionCall('manualStockUpdated', 'portfolioData');
                console.log(response);
            }else{
                toast.success('Saving stock details');
                response = await server.createManualStock(obj);
                await aiftAnalytics.actionCall('manualStockSaved', 'portfolioData');
                console.log(response);
            }
            if(response.data.code !== 200){
                toast.error(response.data.msg);
                setLoading(false);
                return;
            }
            setLoading(false);
            setId(null);
            setPricePerStock(null);
            setPurchasedDate(null);
            setStockName(null);
            setStocksBought(null);
            setTradeFees(null);
            localStorage.removeItem('portfolioColumns');
            localStorage.removeItem('portfolioData');
            localStorage.removeItem('analyticsData');
            localStorage.removeItem('lastRefreshed');
            close(response.data);
        } catch (error) {
            setLoading(false);
            toast.error(error);
        }
    }
    const fileInputRef = useRef(null);

    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);
            console.log(json);
            handleFileUpload(json);
        };

        reader.readAsArrayBuffer(file);
    };

    const handleFileUpload = async (data) => {
        try {
            toast.success('File uploading...');
            const response = await server.createStocksFromFile(data);
            if (response.data.code !== 200) {
                toast.error(response.data.msg);
                return;
            }
            console.log(response.data, 'excel sheet response');
            await aiftAnalytics.actionCall('excelFileUploaded', 'portfolioData');
            localStorage.removeItem('portfolioColumns');
            localStorage.removeItem('portfolioData');
            localStorage.removeItem('analyticsData');
            localStorage.removeItem('lastRefreshed');
            close(response.data);
        } catch (error) {
            toast.error('Failed to upload file');
        }
    };
    useEffect(()=>{
        setLoading(true);
        if(toEdit){
            console.log(toEdit, 'to edit wala data');
            setStockName(toEdit.stockName);
            setPricePerStock(toEdit.pricePerStock);
            setPurchasedDate(toEdit.purchasedDate);
            setStocksBought(toEdit.stocksBought);
            setTradeFees(toEdit.tradeFees);
            setId(toEdit.idx);
        }
        setLoading(false);
    },[toEdit, id])
    return (
        <div style={{marginTop: "5%"}}>
            {
                loading ? (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src="/icons/loadingRipple.gif" style={{height: '5vh'}} alt="" />
                    </div>
                ):(
                    <>
                    <div style={{height: '15vh', display: "flex", alignItems: 'center', justifyContent: 'center'}}>
                    <div onClick={handleDivClick} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2vh 5vh', border: '1px solid gainsboro', borderRadius: '10px'}}>
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyK268_uCOozTFBnQ--1TjU6bDuFCtJ1PqmQ&usqp=CAU" style={{height: '5vh'}} alt="" />
                        Upload file here
                    </div>
                </div>
                <input
                type="file"
                accept=".csv"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
            />
                {
                    stockType!=='excelSheetData' && (
                        <>
                        <div style={{display: 'flex', padding: '1vh'}}>
                    <hr style={{width: '50%'}} />
                    OR
                    <hr style={{width: '50%'}}/>
                </div>
                <div>
                    <div style={{textAlign: 'center'}}>
                        <h4 style={{margin: '1%'}}>Add data manually</h4>
                    </div>
                    <div style={{margin: '2%'}}>
                        <div style={{display: 'flex', flexDirection: (window.innerWidth>760)?'':'column', margin: '1vh 0vh'}}>
                            <div style={{flex: '0.05'}}></div>
                            <div style={{flex: '0.4', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: (window.innerWidth<760)?'1vh':''}}>
                                <label htmlFor="" style={{fontSize: '80%', fontWeight: '600', color: 'gray'}}>
                                    Stock name
                                </label>
                                <input type="text" onChange={(e)=>setStockName(e.target.value)} value={stockName || ''} style={{border: '1px solid rgb(232, 232, 232)',borderRadius: '8px', height: '3vh', outline: 'none'}} />
                            </div>
                            <div style={{flex: '0.1'}}></div>
                            <div style={{flex: '0.4', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <label htmlFor="" style={{fontSize: '80%', fontWeight: '600', color: 'gray'}}>
                                    Stocks bought
                                </label>
                                <input type="number" onChange={(e)=>setStocksBought(e.target.value)} value={stocksBought || ''} style={{border: '1px solid rgb(232, 232, 232)',borderRadius: '8px', height: '3vh', outline: 'none'}} />
                            </div>
                            <div style={{flex: '0.05'}}></div>
                        </div>
                        <div style={{display: 'flex',flexDirection: (window.innerWidth>760)?'':'column', margin: (window.innerWidth>760)?'2vh 0vh':'1vh 0vh'}}>
                            <div style={{flex: '0.05'}}></div>
                            <div style={{flex: '0.4', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: (window.innerWidth<760)?'1vh':''}}>
                                <label htmlFor="" style={{fontSize: '80%', fontWeight: '600', color: 'gray'}}>
                                    Price per stock
                                </label>
                                <input type="number" onChange={(e)=>setPricePerStock(e.target.value)} value={pricePerStock || ''} style={{border: '1px solid rgb(232, 232, 232)',borderRadius: '8px', height: '3vh', outline: 'none'}} />
                            </div>
                            <div style={{flex: '0.1'}}></div>
                            <div style={{flex: '0.4', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <label htmlFor="" style={{fontSize: '80%', fontWeight: '600', color: 'gray'}}>
                                    Purchased date
                                </label>
                                <input type="date" onChange={(e)=>setPurchasedDate(e.target.value)} value={purchasedDate || ''} style={{border: '1px solid rgb(232, 232, 232)',borderRadius: '8px', height: (window.innerWidth>760) ?'3vh':'4vh',width: (window.innerWidth>760)?'56%':'53%', outline: 'none'}} />
                            </div>
                            <div style={{flex: '0.05'}}></div>
                        </div>
                        <div style={{display: 'flex', justifyContent: (window.innerWidth<760)?'space-between':'', margin: (window.innerWidth>760) ?'2vh 0vh':'1vh 0vh'}}>
                            <div style={{flex: '0.05', display: (window.innerWidth<760)?"none":""}}></div>
                            <div style={{flex: (window.innerWidth>760) ?'0.4':'', display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                                <label htmlFor="" style={{fontSize: '80%', fontWeight: '600', color: 'gray'}}>
                                    Trade fees
                                </label>
                                <input onChange={(e)=>setTradeFees(e.target.value)} value={tradeFees || ''} style={{border: '1px solid rgb(232, 232, 232)',borderRadius: '8px', height: '3vh', outline: 'none'}} type="number" />
                            </div>
                        </div>
                    </div>
                </div>
                        </>
                    )
                }
                <div style={{display: 'flex', justifyContent: 'end', alignItems: 'end', height: '10vh'}}>
                    <button onClick={handleSaveData} style={{background: "#6f6fff", fontSize: "1.9vh", color: "white", width: "15vh", height: "4vh", border: 'none', borderRadius: '8px' ,cursor: "pointer"}}>Save</button>
                </div>
                    </>
                )
            }
            <ToastContainer position="top-right"/>
        </div>
    )
}

export default AddStockDataModal;