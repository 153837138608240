import React from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Blog from "../components/landingpage/blog";
import Contact from "../components/landingpage/contact";
import Footer from "../components/landingpage/footer";
import FeaturesContent from "../components/landingpage/FeaturesContent";
import FAQItem from "../components/landingpage/testimonials";

export default function LandingPage() {
	const navigate = useNavigate();

	const handleLoginClick = () => {
		navigate("/login");
	};

	const handleDemoClick = () => {
		window.open(
			"https://calendly.com/tanvit-ai-ft/aift-discovery-and-demonstration",
			"_blank"
		);
	};

	return (
		<div className="flex flex-col min-h-screen">
			{/* Hero Section */}
			<section className="flex flex-col text-white bg-gradient-to-b from-[#010313] to-[#330023] py-12 md:py-24 px-4 md:px-8 lg:px-16 min-h-screen">
				{/* Logo Section */}
				<div className="flex justify-center md:justify-start mb-8">
					<img
						src="icons/AIFT2.png"
						alt="Logo"
						className="h-28 md:h-36 lg:h-48"
					/>
				</div>

				{/* Main Content */}
				<div className="flex flex-col md:flex-row items-center gap-12 md:gap-20 flex-grow">
					{/* Text Section */}
					<div className="flex flex-col items-center md:items-start text-center md:text-left space-y-6 md:w-1/2">
						<h1 className="text-3xl md:text-6xl lg:text-8xl font-bold leading-tight">
							Re-Imagine <br />
							Financial Advisory
							<br />
							with AI
						</h1>
						<p className="text-2xl md:text-3xl lg:text-5xl">
							Streamline, Simplify,
							<br />
							Succeed
						</p>
						<div className="flex flex-col sm:flex-row gap-4 mt-6 w-full sm:w-auto">
							<button
								onClick={handleDemoClick}
								className="bg-gradient-to-r from-pink-500 to-purple-600 text-white py-3 px-8 rounded-full text-lg md:text-xl font-semibold shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out transform hover:-translate-y-1 w-full sm:w-auto"
							>
								Book a Demo
							</button>
							<button
								onClick={handleLoginClick}
								className="bg-gradient-to-r from-blue-500 to-teal-400 text-white py-3 px-8 rounded-full text-lg md:text-xl font-semibold shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out transform hover:-translate-y-1 w-full sm:w-auto"
							>
								Login
							</button>
						</div>
					</div>

					{/* Carousel Section */}
					<div className="w-full md:w-1/2 mt-8 md:mt-0">
						<div className="shadow-lg border border-gray-300 rounded-lg overflow-hidden">
							<Carousel
								showThumbs={false}
								showArrows={false}
								showStatus={false}
								autoPlay
								interval={3000}
								infiniteLoop
								className="rounded-lg"
								renderIndicator={(onClickHandler, isSelected, index, label) => {
									const dotStyle = `w-3 h-3 md:w-4 md:h-4 mx-1 rounded-full ${
										isSelected ? "bg-white" : "bg-gray-400"
									}`;
									return (
										<button
											type="button"
											className={dotStyle}
											onClick={onClickHandler}
											key={index}
											aria-label={`${label} ${index + 1}`}
											tabIndex={0}
										/>
									);
								}}
							>
								{[1, 2, 3, 4].map((num) => (
									<div key={num}>
										<img
											src={`icons/landingPage/dash${num}.png`}
											alt={`Dashboard ${num}`}
											className="w-full h-auto"
										/>
									</div>
								))}
							</Carousel>
						</div>
					</div>
				</div>
			</section>

			{/* Features, Blog, Testimonials, Contact, Footer */}
			<FeaturesContent />
			<Blog />
			<FAQItem />

			<Contact />

			<Footer />
		</div>
	);
}
