import { useState } from "react";
import { ChevronDownIcon } from "lucide-react";

const faqs = [
	{
		question: "How can AI save my business time?",
		answer:
			"We focus on optimizing processes to ensure not a single second of your valuable time is wasted.",
	},
	{
		question:
			"What kind of special offers do you provide for AI implementation?",
		answer:
			"We have tailored offers to help you explore AI solutions if you're still unsure about how they fit your business.",
	},
	{
		question: "How can AI benefit my business in terms of customer service?",
		answer:
			"AI can improve customer interactions by offering faster, more personalized support, leading to top-notch service.",
	},
	{
		question: "How does AI help increase profits?",
		answer:
			"Through AI-powered solutions, you can attract more customers, streamline operations, and ultimately boost profits.",
	},
];

export default function Component() {
	return (
		<div className="bg-gray-50 py-12 sm:py-16 lg:py-20">
			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div className="mx-auto max-w-4xl text-center">
					<h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						FAQs
					</h1>
					<p className="mt-4 text-lg text-gray-500">
						Discover how AI can transform your business
					</p>
				</div>
				<div className="mt-12">
					<dl className="space-y-6 divide-y divide-gray-200">
						{faqs.map((faq, index) => (
							<FAQItem
								key={index}
								question={faq.question}
								answer={faq.answer}
							/>
						))}
					</dl>
				</div>
			</div>
		</div>
	);
}

function FAQItem({ question, answer }) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="pt-6">
			<dt className="text-lg">
				<button
					onClick={() => setIsOpen(!isOpen)}
					className="flex w-full items-start justify-between text-left text-gray-400"
					aria-expanded={isOpen}
				>
					<span className="font-medium text-gray-900">{question}</span>
					<span className="ml-6 flex h-7 items-center">
						<ChevronDownIcon
							className={`${
								isOpen ? "rotate-180" : ""
							} h-6 w-6 transform transition-transform duration-200`}
							aria-hidden="true"
						/>
					</span>
				</button>
			</dt>
			{isOpen && (
				<dd className="mt-2 pr-12">
					<p className="text-base text-gray-500">{answer}</p>
				</dd>
			)}
		</div>
	);
}
