import React, { useEffect, useState } from "react";
import Paginate from './Paginate';
import server from "../../api/Server";
import aiftAnalytics from "../../analytics/aiftAnalytics";
import { RefreshCw, Share2 } from 'lucide-react';

export default function NewsFeed() {
  const [loading, setLoading] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);

  const previousPage = async () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      await aiftAnalytics.actionCall(`${currentPage-1}PaginateButtonClicked`, 'newsFeed');
    }
  };

  const nextPage = async () => {
    if (currentPage !== Math.ceil(blogPosts.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
      await aiftAnalytics.actionCall(`${currentPage+1}PaginateButtonClicked`, 'newsFeed');
    }
  };

  const paginate = async (pageNumber) => {
    setCurrentPage(pageNumber);
    await aiftAnalytics.actionCall(`${pageNumber}PaginateButtonClicked`, 'newsFeed');
  };

  const refreshPage = async () => {
    localStorage.removeItem('lastRefreshed');
    await getNewsFeed();
  }

  const getNewsFeed = async () => {
    try {
      setLoading(true);
      const lastRefreshed = localStorage.getItem('lastRefreshed');
      const now = new Date();

      if (lastRefreshed && localStorage.getItem('newsData')) {
        const lastRefreshedDate = new Date(JSON.parse(lastRefreshed));
        const timeDiff = (now - lastRefreshedDate) / (1000 * 60 * 60);

        if (timeDiff < 24) {
          const cachedData = JSON.parse(localStorage.getItem('newsData'));
          setBlogPosts(cachedData);
          setLoading(false);
          return;
        }
      }
      let resp = await server.getNewsSentiments();
      console.log(resp);
      if (resp.data.code !== 200) {
        setLoading(false);
        return;
      }
      setBlogPosts(resp.data.data.feed);
      localStorage.setItem('newsData', JSON.stringify(resp.data.data.feed));
      localStorage.setItem('lastRefreshed', JSON.stringify(now));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const gotoURL = async (url, idx) => {
    window.open(url, "_blank");
    await aiftAnalytics.actionCall(`${idx}NewsRedirectLinkClicked`, 'newsFeed');
  };

  useEffect(() => {
    getNewsFeed();
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center justify-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Latest News</h1>
        <button
          onClick={refreshPage}
          className="ml-4 p-2 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-300"
          aria-label="Refresh"
        >
          <RefreshCw className="w-5 h-5" />
        </button>
      </div>
      <div className="bg-gray-100 rounded-lg shadow-md p-6">
        {loading ? (
          <div className="flex items-center justify-center min-h-[50vh]">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {currentPosts.map((data, idx) => (
              <div
                key={idx}
                className="bg-white rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1 cursor-pointer"
                onClick={() => gotoURL(data.url, idx)}
              >
                <img 
                  src={data.banner_image} 
                  alt={data.title} 
                  className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2 line-clamp-2">
                    {data.title}
                  </h3>
                  <p className="text-gray-600 mb-4 line-clamp-3">{data.summary}</p>
                  <div className="flex items-center justify-between text-sm text-gray-500">
                    <span>Author: {data.authors && data.authors[0]}</span>
                    <button 
                      className="p-1 rounded-full hover:bg-gray-200 transition-colors duration-300"
                      onClick={(e) => {
                        e.stopPropagation();
                        // Add share functionality here
                      }}
                    >
                      <Share2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {!loading && (
          <div className="mt-8">
            <Paginate
              postsPerPage={postsPerPage}
              totalPosts={blogPosts.length}
              currentPage={currentPage}
              paginate={paginate}
              previousPage={previousPage}
              nextPage={nextPage}
            />
          </div>
        )}
      </div>
    </div>
  )
}