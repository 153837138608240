import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import server from "../../api/Server";
import { ArrowUp, ArrowDown, Calendar, TrendingUp } from "lucide-react";

export default function AnalyticsHeader() {
  const [totalInvestments, setTotalInvestments] = useState(null);
  const [lastTrade, setLastTrade] = useState(null);
  const [stockStatus, setStockStatus] = useState({ up: 0, down: 0 });
  const [loading, setLoading] = useState(false);

  const daysAgo = (dateString) => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    const diffInMs = currentDate - inputDate;
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    return `${diffInDays} days ago`;
  };

  useEffect(() => {
    const getAllData = async () => {
      setLoading(true);
      const lastRefreshed = localStorage.getItem('lastRefreshed');
      const now = new Date();

      if (lastRefreshed && localStorage.getItem('analyticsData')) {
        const lastRefreshedDate = new Date(JSON.parse(lastRefreshed));
        const timeDiff = (now - lastRefreshedDate) / (1000 * 60 * 60);

        if (timeDiff < 24) {
          const cachedData = JSON.parse(localStorage.getItem('analyticsData'));
          if (cachedData && cachedData.lastTrade && cachedData.totalInvestments && cachedData.stockStatus) {
            setLastTrade(cachedData.lastTrade);
            setTotalInvestments(cachedData.totalInvestments);
            setStockStatus(cachedData.stockStatus);
          }
          setLoading(false);
          return;
        }
      }

      try {
        const res = await server.getDashboardDetails();
        console.log(res);

        if (res.data.code !== 200) {
          toast.error(res.data.msg);
          setLoading(false);
          return;
        }

        const totalInvestments = res.data.data.totalInvestments;
        const date = daysAgo(res.data.data.lastTradeDate);
        const status = res.data.data.stockStatus;
        setTotalInvestments(totalInvestments);
        setLastTrade(date);
        setStockStatus(status);

        const newData = { lastTrade: date, totalInvestments, stockStatus: status };
        localStorage.setItem('analyticsData', JSON.stringify(newData));
        localStorage.setItem('lastRefreshed', JSON.stringify(now));
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    getAllData();
  }, []);

  const StatCard = ({ title, value, icon: Icon }) => (
    <div className="bg-white rounded-xl shadow-md p-6 transition-all duration-300 hover:shadow-lg">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold text-gray-700">{title}</h3>
        {Icon && <Icon className="w-6 h-6 text-blue-500" />} {/* Only render Icon if it's provided */}
        </div>
      {loading ? (
        <div className="flex justify-center items-center h-12">
          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <p className="text-3xl font-bold text-gray-900">{value}</p>
      )}
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <StatCard
          title="Total Investments"
          value={totalInvestments ? `₹${totalInvestments}` : '-'}
         
        />
        <StatCard
          title="Stocks Status"
          value={
            <div className="flex items-center justify-center space-x-4">
              <div className="flex items-center">
                <span className="text-green-500 mr-2">{stockStatus.up}</span>
                <ArrowUp className="w-5 h-5 text-green-500" />
              </div>
              <div className="flex items-center">
                <span className="text-red-500 mr-2">{stockStatus.down}</span>
                <ArrowDown className="w-5 h-5 text-red-500" />
              </div>
            </div>
          }
          icon={TrendingUp}
        />
        <StatCard
          title="Last Trade"
          value={lastTrade || '-'}
          icon={Calendar}
        />
      </div>
    </div>
  );
}